.footer-detail {
    background: #1E232E;
    padding: 3rem;
}

.company-footer-detail h5 {
    color: #FFF;
    margin-top: 20px;
}

.company-footer-detail p {
    margin-top: 15px;
    color: rgb(124, 118, 118);
    font-weight: 500;


}

.social-icons {
    display: flex;
    gap: 40px;
}

.social-icons span {
    margin-top: 15px;
    color: rgb(124, 118, 118);
    cursor: pointer;
}

.social-icons span:hover {
    color: #4A6CF7;
}


.footer-detail .other-links h3 {
    color: #FFF;
    margin-top: 20px;
    font-weight: 700;

}

.footer-detail .other-links ul li {
    color: rgb(124, 118, 118);
    list-style: none;
    margin-top: 20px;
    font-weight: 500;
    cursor: pointer;

}

.footer-detail .other-links ul li:hover {
    color: #4A6CF7;

}

.footer-detail .footer-terms h3 {
    color: #FFF;
    margin-top: 20px;
    font-weight: 700;

}

.footer-detail .footer-terms ul li {
    color: rgb(124, 118, 118);
    list-style: none;
    margin-top: 20px;
    font-weight: 500;
    cursor: pointer;

}

.footer-detail .footer-terms ul li:hover {
    color: #4A6CF7;
}

.footer-detail .footer-help-section ul li {
    color: rgb(124, 118, 118);
    list-style: none;
    margin-top: 20px;
    font-weight: 500;
    cursor: pointer;
}

.footer-detail .footer-help-section ul li:hover {
    color: #4A6CF7;

}

.footer-detail .copyright-section p {
    color: #FFF;
    text-align: center;
    font-weight: 600;
    margin-top: 30px;
}