body {
  overflow-x: hidden;
}
.logo-wrapper {
  width: 100px;
}

.nav-link {
  color: #FFF !important;
  font-size: 20px !important;
}

.navbar a {
  color: #FFF !important;
  margin-top: 10px !important;
}

.navbar1.colorChange {
  background-color: #1E232E !important;
  z-index: 1000;
  transform: translateZ(0);
}

.navbar {
  background: #1E232E !important;
}
.navbar-light .navbar-toggler{
  color: #fff !important;
}


.contact-detail a {
  color: #FFF !important;
  font-weight: 600;
  margin-left: 40px;
  font-size: 13px;
  margin-top: 50px !important;
}

.contact-detail p {
  color: #FFF !important;
  font-weight: 600;
  margin-left: 40px;
  font-size: 14px;
}

.contact-detail i {
  color: #FFF !important;
  font-weight: 600;
  margin-left: 40px !important;
  font-size: 18px;
  margin-top: 31px;
}


.Home-section {
  background: rgb(30 35 46) !important;
}

.company-detail {
  background: #1E232E;
  padding-bottom: 50px;
  padding-top: 60px;
  /* padding-top: 20rem; */

}

.company-detail-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.company-detail .company-detail-heading h1 {
  color: #FFF !important;
  text-align: center;
  font-size: 50px;
  width: 700px !important;

}

.company-detail .company-detail-heading p {
  color: rgb(149 156 177);
  text-align: center;
  font-size: 20px;
  width: 50rem;
}

.heading-primary h1 {
  padding-top: 20px;
  color: #FFF;
  margin-bottom: 0px !important;
  text-align: start !important;
}

.object-section {
  padding-bottom: 70px;
  padding-top: 10px;
}

.object-section-detail {
  background-color: #121723;

}

.object-section h1 {
  padding-top: 40px;
  color: #FFF;
  text-align: center;
  /* margin-top: 100px; */
}

.object-section .card {
  background: none !important;
  /* color: #FFF !important; */
  border: none;
  padding-top: 50px;
}

.card-body .card-title {
  color: #FFF !important;
  font-size: 27px;
  font-weight: 700;
}

.card-text {
  color: #4a546d;
  font-size: 18px !important;
  /* text-align: justify; */
}


.object-section .menu-icon-image {
  background-color: #182038;
  width: 70px;
  height: 70px;
  border-radius: 5px;
  margin-left: 15px;
}

.object-section .card img {
  margin: 15px;
  padding-top: 6px;
  width: 30px;
  margin-left: 18px;
}

.help-section-center {
  background-color: #121723;
  padding-bottom: 10rem;
}

.help-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.help-section h1 {
  color: #FFF;

  padding-top: 100px;
  font-size: 50px;
  font-weight: 700;
}

.help-section p {
  margin-top: 10px;
  text-align: center;
  color: rgb(105, 107, 112);
  width: 40rem;
}

.utube-video-section {
  /* display: flex;
  justify-content: center; */
  /* align-items: center; */
  width: 100% !important;
  padding-bottom: 20px;
  margin-top: 40px;
}


.utube-video-section  iframe{
  width: 100% !important;
}

.utube-video-section img {
  width: 100%;
  margin-top: 50px;
}

.company-partners-section {
  margin-top: 100px;
}

.patner-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  background-color: #1E232E;
  flex-wrap: wrap;
}

.patner-img img {
  padding: 30px;
  width: 200px;
  cursor: pointer;

}

.patner-img img:hover {
  filter: brightness(1.3) !important;
}

.deals-detail-section {
  margin-top: 100px;
}
.deals-detail-section .gallery{
  background-color: #1D2430;
  padding: 30px;
}
.deals-detail-section h1 {
  color: #FFF;
}

.supplier-detail {
  display: flex;
  align-items: center;
  gap: 20px;
}

.supplier-detail img {
  width: 40px;
  background-color: #182038;
  padding: 10px;
  border-radius: 10px;
}

.supplier-detail h6 {
  color: #959cb1 !important;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}

.Premier-support-section h1 {
  color: #FFF;
  font-weight: 700;
  font-size: 30px;
}

.Premier-support-section p {
  color: #a7a3a3;
  /* font-weight: 700; */
  /* font-size: 30px; */
}

.client-review-section {
  background: #171C28;
  padding-bottom: 10rem;
}

.client-review-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.client-review-section .client-review-heading h1 {
  color: #FFF;
  text-align: center;
}

.client-review-section .client-review-heading p {
  color: #7e7373;
  text-align: center;
  width: 30rem;
}

.customer-review-section .rating-star {
  padding: 30px;

}


.customer-review-section .rating-star span {
  color: #FEC223;
  width: 20px;
  margin-left: 10px;

}

.customer-review-section {
  background: #1D2430;
  /* padding-bottom: 40px; */
}

.customer-review-section p {
  color: #FFF;
  /* padding: 30px;  */
}

.customer-profile {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding-left: 20px;
  padding-bottom: 10px;
}

.customer-profile h6 {
  font-size: 16px;
  color: #FFF;
  margin-left: 20px;
  margin-top: 16px;
  font-weight: 700;
}

.customer-profile p {
  font-size: 14px;
  color: #a7a0a0;
  margin-left: 20px;
  /* font-weight: 700; */
}

.pricing-detail-section {
  background: #121723;
  /* padding: 15px; */
}

.pricing-detail-section {
  background: #121723;
  padding-bottom: 10rem;
}

.pricing-detail-heading {

  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;

}

.pricing-detail-heading h1 {
  color: #FFF;
  font-weight: 700;
  padding-top: 50px;
}

.pricing-detail-heading p {
  color: #7e7373;
  text-align: center;
  width: 30rem;
}

.pricing-detail {
  background: #1D2430;
  padding: 20px;

}

.offer-price {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.pricing-detail .offer-price h1 {
  color: #FFF;
  font-size: 25px;
  font-weight: 700;
}

.pricing-detail .offer-price p {
  color: #FFF;
  font-size: 25px;
  font-weight: 700;
}

.pricing-detail .offer-pricing-para {

  color: #979090;
  font-size: 16px;
  font-weight: 600;
}

.offering-detail img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #232A42;
  padding: 5px;
}

.offering-detail p {
  color: #979090;
  margin-left: 8px;
}

.blog-detail-section {
  background: #171C28;
  padding-bottom: 10rem;
}

.blog-detail-section .blog-detail-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-detail-section .blog-detail-heading h1 {
  color: #FFF;
  margin-top: 6rem;
  font-size: 60px;
}

.blog-detail-section .blog-detail-heading p {
  color: #7e7373;
  text-align: center;
  width: 30rem;
}

.hover-1-content {
  padding: 26px !important;
  background: #1D2430 !important;
  width: 94.5%;
}

.hover-1-content .title {
  color: #FFF;
  width: 20rem !important;
  font-size: 27px;
  font-weight: 700;
  cursor: pointer;
}

.hover-1-content .title:hover {
  color: #4A6CD7 !important;
}

.hover-1-content p {
  color: #4a546d;
  width: 20rem !important;

}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: #545d6d !important;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  margin-right: 26px !important;
  margin-top: -78px !important;
}

.react-multiple-carousel__arrow:hover {
  background: #171C28 !important;
}

.profile-imgs img {
  width: 40px;
  height: 40px;
  margin-top: 7px;
}

.profile-person-detail p {
  /* margin-top: 5px; */
  color: #FFF;
  margin-left: 20px;
  margin-bottom: 0 !important;
}

.profile-person-detail span {

  color: grey;
  margin-left: 20px;
}

.contact-form-section {
  background: #121723;
  padding-bottom: 10rem;
}


.contact-form-section .contact-form-heading h1 {
  color: #FFF;
  padding-top: 3rem;
  padding-left: 40px;
  /* padding-top: 10rem; */
}

.contact-form-section .contact-form-heading p {
  color: grey;
  padding-left: 40px;
}

.contact-form {
  margin-top: 5rem;
  background: #1E232E;
}

.contact-form-section .contact-form label {
  color: #FFF;
  font-weight: 600;
  /* padding: 30px; */
  margin-left: 10px;
  padding-bottom: 0px;

}

.contact-form-section .contact-form input {
  color: #FFF;
  /* padding: 30px; */
  margin-top: 10px;
  /* margin-left: 37px; */
  padding: 10px;
  border-radius: 6px;
  background: #2C303B !important;
  border: none;
}

.contact-form-section .contact-form textarea {
  color: #FFF;
  /* padding: 30px; */
  margin-top: 10px;
  /* margin-left: 37px; */
  /* width: 100%; */
  padding: 0px 10px 10px;
  border-radius: 6px;
  background: #2C303B !important;
  border: none !important;

}

.contact-form-section .contact-form input::placeholder {
  color: grey !important;
  font-weight: 500;

}

.contact-form-section .submit-btn{
  display: flex;
  justify-content: end;
}
.contact-form-section .submit-btn button {
  background-color: #4A6CF7;
  padding: 10px 20px;
  margin-left: 37px;
  margin-top: 20px;
  /* margin-bottom: 20px; */
  border: none;
  color: #FFF;
  font-weight: bold !important;
  border-radius: 10px !important;
}

.footer-section {
  background: #1E232E;
}

.carousel-control-prev {
  width: 50px;
  margin-left: 5rem;
}

.carousel-control-next {
  width: 50px;
  margin-right: 7rem;
}


.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 50px;
  background-color: #415DCF;
  color: white;
  font-size: 20px;
  padding: 5px  18px;
  border-radius: 10px;
  cursor: pointer;
  display: none;
}

.scroll-to-top.visible {
  display: block;
}

.deals-map-img {
  width: 100% !important;
}


/* Responsive */


@media (max-width: 778px) {
  .help-section h1 {
    font-size: 27px !important;
  }

  .help-section p {
    font-size: 16px !important;
    width: 20rem !important;
  }

  .patner-img {
    display: block !important;
  }

  .deals-map-img {
    width: 100% !important;
    margin-top: 50px !important;
    margin-left: 0px !important;
    /* padding-left: 10px !important; */
    /* padding: 10px; */

    /* margin-left: 10px; */
  }

  .bug-fixer-section-img {
    width: 100%;
    padding: 10px;
    /* margin-left: 10px; */
  }

  .client-review-heading p {
    width: 20rem !important;
  }

  .rating-star {
    margin-top: 20px;
  }

  .pricing-detail-heading h1 {
    font-size: 20px !important;
    text-align: center;
  }

  .pricing-detail-heading p {
    width: 20rem !important;

  }

  .pricing-detail {
    margin-top: 20px;
  }

  .blog-detail-heading h1 {
    font-size: 30px !important;
    text-align: center;
    font-weight: 700 !important;
  }

  .blog-detail-heading p {
    width: 20rem !important;
  }

  .contact-form-section .contact-form input {
    color: #FFF;
    /* padding: 30px; */
    margin-top: 10px;
    /* margin-left: 37px; */
    padding: 15px 40px;
    border-radius: 6px;
    background: #2C303B !important;
    border: none;
  }

  .contact-form iframe {
    width: 100% !important;
  }

  .deals-detail-section h1 {
    font-size: 20px !important;
    margin-bottom: 40px;
  }

  .supplier-detail {
    margin-top: 10px !important;
  }

  .supplier-detail h6 {
    font-size: 10px !important;
    font-weight: 400 !important;
    margin-right: 20px !important;

  }

  .supplier-detail img {
    width: 30px;
  }

  .navbar a {
    font-size: 10px !important;
    margin-left: 20px !important;
    font-weight: normal !important;
  }

  .navbar p {
    font-size: 10px !important;
    margin-left: 20px !important;

    font-weight: normal !important;
  }

  .navbar i {
    font-size: 20px !important;
  }
  .main-carosuel h1{
    width: 20rem !important;
    font-size: 25px !important  ;
    text-align: center;
  }
  .main-carosuel p{
    color: #959cb1;
    font-size: 10px;
    width: 20rem !important;
    margin-top: 20px;
    text-align: center;
  }
  .carousel-control-prev,.carousel-control-next{
    margin: 0;
  }
  .slick-slide p{
   font-size: 12px;
    width: 20rem !important;
  
  }
  
}


/* animatiom */
.animation-section {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 2s ease, transform 1s ease;
}

.animation-section.appear {
  opacity: 1;
  transform: translateY(0);
}
.main-carosuel{
  background-color: #121723;
  color: #fff;
}
.main-carosuel h1{
  font-size: 50px;
  text-align: center;
  width: 50rem;
}
.main-carosuel p{
  color: #959cb1;
  font-size: 20px;
  width: 50rem;
  margin-top: 20px;
  text-align: center;
}
.carosuel-content{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30rem !important; 
  margin-top: 100px;
}

.contact-form-section textarea{
  width: 100%;

}

.contact-form-section .contact-form input{
  width: 100%;
  display: block;
}

.slider{
  background-color: #121723;
  color: hsl(0, 0%, 100%);
  padding: 150px 0px;
 
}
.slick-slide p{
  margin: 0 auto;
  text-align: center;
  width: 60rem;
  margin-top: 20px;

}
